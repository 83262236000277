<template>
  <v-form>
    <v-container>
      <v-row>
        <v-col cols="12" md="3">
          <v-autocomplete
            :color="colors.mainPurple"
            v-model="operacion.experiencia"
            :items="experiencias"
            filled
            label="Experiencia"
            prepend-icon="mdi-send-outline"
            no-data-text="No se encuentra ninguna experiencia con ese nombre"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="3">
          <v-menu
            v-model="datePickerShow"
            :close-on-content-click="false"
            min-width="auto"
            :nudge-right="200"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="operacion.fecha"
                label="Fecha"
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                filled
                :color="colors.mainPurple"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="operacion.fecha"
              @input="datePickerShow = false"
              :color="colors.mainPurple"
              max-width="auto"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            v-model="operacion.hora"
            prepend-icon="mdi-timer"
            label="Hora"
            required
            type="time"
            filled
            :color="colors.mainPurple"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <v-autocomplete
            :color="colors.mainPurple"
            v-model="operacion.aerodromo"
            :items="aerodromos"
            filled
            label="Aeródromo"
            prepend-icon="mdi-airplane-marker"
            no-data-text="No se encuentra ninguna experiencia con ese nombre"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <v-autocomplete
            :color="colors.mainPurple"
            v-model="operacion.operador"
            :items="operadores"
            filled
            label="Operador"
            prepend-icon="mdi-handshake"
            no-data-text="No se encuentra ningun operador con ese nombre"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <v-autocomplete
            :color="colors.mainPurple"
            v-model="operacion.aeronave"
            :items="aeronaves"
            filled
            label="Aeronave"
            prepend-icon="mdi-helicopter"
            no-data-text="No se encuentra ningun operador con ese nombre"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            :color="colors.mainPurple"
            v-model="operacion.piloto"
            filled
            label="Piloto"
            prepend-icon="mdi-account-tie-hat"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <v-select
            prepend-icon="mdi-list-status"
            filled
            v-model="operacion.estado"
            :items="estadosDeOperacion"
            :color="colors.mainPurple"
            label="Estado"
            dense
          ></v-select>
        </v-col>
      </v-row>

      <hr class="mb-10" />

      <v-row>
        <v-col cols="12" md="5">
          <div class="d-flex" v-for="(venta, i) in operacion.ventas" :key="i">
            <v-autocomplete
              :color="colors.mainPurple"
              v-model="operacion.ventas[i]"
              :items="ventas"
              outlined
              label="Venta relacionada"
              prepend-icon="mdi-account"
              no-data-text="No se encuentra ninguna venta con ese nombre"
            ></v-autocomplete>
            <v-btn
              @click="removeVentaDeLaOperacion(i)"
              class="mx-2 mt-3"
              fab
              dark
              x-small
              color="red"
              v-if="operacion.ventas.length > 1"
            >
              <v-icon dark>
                mdi-delete
              </v-icon>
            </v-btn>
            <v-btn
              class="mx-2 mt-3"
              fab
              dark
              x-small
              :color="colors.mainPurple"
              @click="addVentaALaOperacion"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </div>
        </v-col>

        <v-col
          cols="12"
          md="7"
          class="pl-12"
          v-show="pasajerosDeTodasLasVentas.length"
        >
          <div class="mb-4 d-flex align-center">
            <h3>
              Pasajeros
            </h3>
            <v-btn
              class="ml-3"
              x-small
              fab
              :color="colors.mainPurple"
              dark
              @click="registrarPasajeronModal = true"
              ><v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </div>

          <li
            v-for="({ nombre, rut, telefono, peso }, i) in operacion.pasajeros"
            :key="i"
          >
            <span
              :class="{ 'font-weight-bold': operacion.pasajeros[i].comprador }"
              >{{ nombre }}</span
            >
            | RUT: {{ rut }} | Telf: {{ telefono }} | {{ peso }}kg
            <span style="cursor: pointer" @click="eliminarPasajero(i)"
              >(❌)</span
            >
          </li>
          <p class="pl-6 pt-3">
            Peso total: <span class="font-weight-bold">{{ pesoTotal }} kg</span>
          </p>
        </v-col>
        <v-dialog v-model="registrarPasajeronModal">
          <v-card class="px-15 py-10">
            <div class="pl-3 mb-3">
              <h1>Registrar pasajero</h1>
              <v-divider></v-divider>
            </div>
            <NuevoPasajeroForm @registrarPasajero="registrarPasajero" />
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>

    <v-row class="d-flex justify-end">
      <v-btn
        v-if="btnLabel"
        class="mr-8"
        color="red"
        dark
        @click="$emit('eliminarOperacion')"
      >
        Eliminar operacion
      </v-btn>
      <v-btn :color="colors.mainPurple" dark @click="registrarOperacion">
        {{ btnLabel ? btnLabel : "        Registrar operación" }}
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import { mapState } from "vuex";
import NuevoPasajeroForm from "./NuevoPasajeroForm.vue";
import utils from "./utils";
const { estadosDeOperacion } = utils;
export default {
  name: "OperacionForm",
  props: {
    operacion: {
      type: Object,
      default: () => ({
        experiencia: "",
        fecha: "",
        hora: "",
        aerodromo: "",
        operador: "",
        aeronave: "",
        estado: "",
        pasajeros: [],
        ventas: [{}],
      }),
    },
    btnLabel: {
      type: String,
    },
  },
  data() {
    return {
      estadosDeOperacion,
      registrarPasajeronModal: false,
      datePickerShow: false,
    };
  },
  methods: {
    addVentaALaOperacion() {
      this.operacion.ventas.push({});
    },
    removeVentaDeLaOperacion(i) {
      this.operacion.ventas.splice(i, 1);
    },
    registrarPasajero(pasajero) {
      this.operacion.pasajeros.push(pasajero);
      this.registrarPasajeronModal = false;
    },
    eliminarPasajero(i) {
      this.operacion.pasajeros.splice(i, 1);
    },
    registrarOperacion() {
      const { operacion } = this;
      const faltanCamposPorLlenar = !Object.values(operacion).every(Boolean);
      if (faltanCamposPorLlenar) return alert("Faltan campos por llenar");
      const result = this.$emit("registrarOperacion", operacion);
      if (result)
        this.operacion = {
          experiencia: "",
          fecha: "",
          hora: "",
          aerodromo: "",
          operador: "",
          aeronave: "",
          venta: "",
        };
    },
  },
  computed: {
    ...mapState(["colors"]),
    ...mapState("Aeronaves", ["aeronaves"]),
    operadores() {
      return this.$store.state.Operadores.operadores.map((o) => ({
        value: { id: o.id, name: o.nombre },
        text: o.nombre,
      }));
    },
    aerodromos() {
      return this.$store.state.Aerodromos.aerodromos.map((a) => ({
        value: { id: a.id, name: a.data.nombre },
        text: a.data.nombre,
      }));
    },
    experiencias() {
      return this.$store.state.Experiencias.experiencias.map((e) => ({
        value: { id: e.id, name: e.data.destino },
        text: e.data.destino,
      }));
    },
    ventas() {
      return this.$store.state.Ventas.ventas.map((v) => ({
        value: {
          id: v.id,
          fecha: v.data.date,
          emailComprador: v.data.userData.email,
          comprador: v.data.userData.displayName,
          pasajeros: v.data.details.listaPasajeros,
        },
        text: `${v.data.date} - ${v.data.userData.displayName}`,
      }));
    },
    pasajerosDeTodasLasVentas() {
      const { ventas } = this.operacion;
      const pasajerosDeTodasLasVentas = ventas
        .map((v) => v.pasajeros)
        .flat()
        .filter(Boolean);
      console.log(ventas);
      this.operacion.pasajeros = pasajerosDeTodasLasVentas;
      return pasajerosDeTodasLasVentas;
    },
    pesoTotal() {
      const { pasajeros } = this.operacion;
      return pasajeros.reduce((a, b) => a + +b.peso, 0);
    },
  },
  components: {
    NuevoPasajeroForm,
  },
};
</script>

<style></style>
