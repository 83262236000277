<template>
  <div>
    <h1 class="d-flex justify-space-between align-center">
      <span>Aerodromos ({{ aerodromos.length }})</span>
      <v-btn
        small
        @click="showNewAerodromoForm = true"
        icon
        style="background: #574dad; color: white"
        ><v-icon>mdi-plus</v-icon></v-btn
      >
    </h1>
    <hr class="mb-5" />
    <div class="col-7 px-0" v-show="showNewAerodromoForm">
      <v-card elevation="5" class="pa-4">
        <h3>Nuevo Aeródromo</h3>
        <v-text-field label="Nombre" v-model="nuevoAerodromo.nombre">
        </v-text-field>
        <gmap-autocomplete
          style="border-bottom: solid 1px black"
          class="mb-4"
          placeholder="Ingrese aquí la dirección del NUEVO aeródromo"
          @place_changed="setPlaceNuevoAerodromo"
          :options="{
            componentRestrictions: { country: 'cl' },
            fields: ['geometry', 'formatted_address'],
            strictBounds: true,
          }"
        >
        </gmap-autocomplete>

        <v-btn @click="agregarAerodromo" color="#574dad" class="white--text"
          >Agregar</v-btn
        >
      </v-card>
    </div>

    <v-text-field
      color="#574dad"
      v-model="search"
      label="Buscador por nombre"
      prepend-icon="mdi-magnify"
    >
    </v-text-field>
    <v-card elevation="5"> </v-card>

    <div class="aerodromos row">
      <p class="pa-3" v-if="!aerodromosFiltradosPorNombre.length">
        No hay aeródromos con este nombre
      </p>

      <div
        v-else
        v-for="({ data: aerodromo, id }, i) in aerodromosFiltradosPorNombre"
        :key="i"
        class="pa-3 col-6"
      >
        <v-card elevation="5" class="pa-3 text-center">
          <v-text-field
            label="Nombre"
            v-model="aerodromo.nombre"
            @keypress="formulariosModificados[i] = true"
          >
          </v-text-field>
          <v-text-field
            label="Correo de contacto"
            v-model="aerodromo.email"
            @keypress="formulariosModificados[i] = true"
          >
          </v-text-field>
          <div class="mb-5" style="border-bottom: 1px solid black">
            <gmap-autocomplete
              :value="aerodromo.direccion"
              placeholder="Ingrese aquí la dirección del aeródromo"
              @place_changed="setPlace(arguments[0], i)"
              :options="{
                componentRestrictions: { country: 'cl' },
                fields: ['geometry', 'formatted_address'],
                strictBounds: true,
              }"
            >
            </gmap-autocomplete>
          </div>

          <GmapMap
            :center="{
              lat: aerodromo.position.lat,
              lng: aerodromo.position.lng,
            }"
            :zoom="zoom"
            style="width: 100%; height: 300px"
            map-type-id="hybrid"
          >
            <GmapMarker
              ref="myMarker"
              :position="{
                lat: aerodromo.position.lat,
                lng: aerodromo.position.lng,
              }"
            />
          </GmapMap>

          <v-textarea
            v-model="aerodromo.informacion"
            class="mt-3"
            @input="formulariosModificados[i] = true"
            label="Información relevante"
          ></v-textarea>

          <v-btn
            @click="updateAerodromo(i, id)"
            :color="formulariosModificados[i] ? '#574dad' : ''"
            :class="{ 'white--text': formulariosModificados[i] }"
            >Actualizar</v-btn
          >
          <v-btn
            @click="eliminarAerodromo(id)"
            color="red"
            class="white--text ml-4"
            >Eliminar</v-btn
          >
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import firebase from "firebase";

export default {
  name: "Aerodromos-Admin",
  data() {
    return {
      zoom: 10,
      search: "",
      formulariosModificados: {},
      nuevoAerodromo: { position: {} },
      showNewAerodromoForm: false,
    };
  },
  computed: {
    ...mapState("Aerodromos", ["aerodromos"]),
    aerodromosFiltradosPorNombre() {
      const { search } = this;
      return this.aerodromos.filter((a) =>
        a.data.nombre.toLowerCase().includes(search.toLowerCase())
      );
    },
  },
  methods: {
    async updateAerodromo(index, id) {
      const { aerodromos } = this;
      const aerodromo = aerodromos.find((a) => a.id === id);
      const nombre = aerodromo.data.nombre;
      await firebase
        .firestore()
        .collection("aerodromos")
        .doc(id)
        .set(aerodromo.data);
      alert("Información del aeródromo " + nombre + " actualizada");
      this.formulariosModificados[index] = false;
    },
    setPlace(address, index) {
      const { aerodromos } = this;
      const { formatted_address } = address;
      const lat = address.geometry.location.lat();
      const lng = address.geometry.location.lng();
      aerodromos[index].data.direccion = formatted_address;
      aerodromos[index].data.position.lat = lat;
      aerodromos[index].data.position.lng = lng;
      this.formulariosModificados[index] = true;
    },
    setPlaceNuevoAerodromo(address) {
      const { formatted_address } = address;
      const lat = address.geometry.location.lat();
      const lng = address.geometry.location.lng();
      this.nuevoAerodromo.direccion = formatted_address;
      this.nuevoAerodromo.position.lat = lat;
      this.nuevoAerodromo.position.lng = lng;
    },
    async agregarAerodromo() {
      const { nuevoAerodromo: aerodromo } = this;
      const { nombre, direccion } = aerodromo;
      if (!nombre || !direccion)
        return alert("Debe escribir nombre Y dirección");
      await firebase
        .firestore()
        .collection("aerodromos")
        .add(aerodromo);
      alert("Aeródromo " + nombre + " agregado con éxito");
      this.showNewAerodromoForm = false;
    },
    async eliminarAerodromo(id) {
      const decision = confirm(
        "Estás seguro de querer eliminar este aeródromo?"
      );
      if (decision) {
        await firebase
          .firestore()
          .collection("aerodromos")
          .doc(id)
          .delete();
        alert("Aeródromo eliminado");
      }
    },
  },
  components: {
    VueGoogleAutocomplete,
  },
};
</script>

<style>
.pac-container:after {
  background-image: none !important;
  height: 0px;
}

.pac-target-input {
  width: 100%;
}
</style>
