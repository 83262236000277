<template>
  <div id="Admin" class="py-5">
    <router-link to="/admin">
      <h1 class="text-center mb-5">HummingBird admin</h1>
    </router-link>
    <router-link to="/">
      <p class="text-center mb-5 text-purple">Ir a Página principal</p>
    </router-link>
    <div class="opciones font-weight-bold text-center mb-5">
      <!-- <router-link class="opcion" :to="{ name: 'Noticias-Admin' }">Noticias</router-link> -->
      <router-link
        class="opcion"
        :class="{ 'opcion-active': rutaActual === 'Operaciones' }"
        :to="{ name: 'Operaciones' }"
        >Operaciones</router-link
      >
      <router-link
        class="opcion"
        :class="{ 'opcion-active': rutaActual === 'Viajes' }"
        :to="{ name: 'Viajes' }"
        >Ver viajes</router-link
      >

      <router-link
        class="opcion"
        :class="{ 'opcion-active': rutaActual === 'AgregarExperiencia' }"
        :to="{ name: 'AgregarExperiencia' }"
        >Agregar experiencia</router-link
      >

      <router-link
        class="opcion"
        :class="{ 'opcion-active': rutaActual === 'Ventas' }"
        :to="{ name: 'Ventas' }"
        >Ver ventas</router-link
      >
      <router-link
        class="opcion"
        :class="{ 'opcion-active': rutaActual === 'AerodromosAdmin' }"
        :to="{ name: 'AerodromosAdmin' }"
        >Aeródromos</router-link
      >

      <router-link
        class="opcion"
        :class="{ 'opcion-active': rutaActual === 'Busquedas' }"
        :to="{ name: 'Busquedas' }"
        >Busquedas Realizadas</router-link
      >

      <router-link
        class="opcion"
        :class="{ 'opcion-active': rutaActual === 'Cotizaciones' }"
        :to="{ name: 'Cotizaciones' }"
        >Cotizaciones recibidas</router-link
      >
      <router-link
        class="opcion"
        :class="{ 'opcion-active': rutaActual === 'AgregarOperador' }"
        :to="{ name: 'AgregarOperador' }"
        >Agregar operador</router-link
      >

      <router-link
        class="opcion"
        :class="{ 'opcion-active': rutaActual === 'Usuarios' }"
        :to="{ name: 'Usuarios' }"
        >Usuarios</router-link
      >
      <router-link
        class="opcion"
        :class="{ 'opcion-active': rutaActual === 'Subscriptores' }"
        :to="{ name: 'Subscriptores' }"
        >Subscriptores</router-link
      >

      <router-link
        class="opcion"
        :class="{ 'opcion-active': rutaActual === 'Calificaciones' }"
        :to="{ name: 'Calificaciones' }"
        >Calificaciones</router-link
      >
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
export default {
  name: "Admin",
  mounted() {
    this.GET_NOTICIAS();
    this.getVentas();
    this.GET_OPERACIONES();
  },

  methods: {
    ...mapActions("Ventas", ["getVentas"]),
    ...mapMutations("Noticias", ["GET_NOTICIAS"]),
    ...mapMutations("Operaciones", ["GET_OPERACIONES"]),
  },
  computed: {
    rutaActual() {
      return this.$route.name;
    },
  },
  title() {
    return `Admin - HummingBird`;
  },
};
</script>

<style lang="scss" scoped>
#Admin {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 50px;
  padding: 0px 50px 0px 5px;
}

.opciones {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}

.opcion {
  padding: 10px 15px;
  background: gray;
  border-radius: 5px;
  margin: 4px 10px;
  opacity: 0.8;
  color: rgb(246, 246, 246) !important;
  background: #574dad;
}

.opcion-active {
  opacity: 1;
}
</style>
