const estadosDeOperacion = [
  "Interesado",
  "Vendido",
  "Confirmado",
  "Reagendado",
  "Cancelado",
  "Devuelto",
  "Finalizada",
];

export default {
  estadosDeOperacion,
};
