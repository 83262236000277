var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"d-flex justify-space-between align-center"},[_c('span',[_vm._v("Aerodromos ("+_vm._s(_vm.aerodromos.length)+")")]),_c('v-btn',{staticStyle:{"background":"#574dad","color":"white"},attrs:{"small":"","icon":""},on:{"click":function($event){_vm.showNewAerodromoForm = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('hr',{staticClass:"mb-5"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showNewAerodromoForm),expression:"showNewAerodromoForm"}],staticClass:"col-7 px-0"},[_c('v-card',{staticClass:"pa-4",attrs:{"elevation":"5"}},[_c('h3',[_vm._v("Nuevo Aeródromo")]),_c('v-text-field',{attrs:{"label":"Nombre"},model:{value:(_vm.nuevoAerodromo.nombre),callback:function ($$v) {_vm.$set(_vm.nuevoAerodromo, "nombre", $$v)},expression:"nuevoAerodromo.nombre"}}),_c('gmap-autocomplete',{staticClass:"mb-4",staticStyle:{"border-bottom":"solid 1px black"},attrs:{"placeholder":"Ingrese aquí la dirección del NUEVO aeródromo","options":{
          componentRestrictions: { country: 'cl' },
          fields: ['geometry', 'formatted_address'],
          strictBounds: true,
        }},on:{"place_changed":_vm.setPlaceNuevoAerodromo}}),_c('v-btn',{staticClass:"white--text",attrs:{"color":"#574dad"},on:{"click":_vm.agregarAerodromo}},[_vm._v("Agregar")])],1)],1),_c('v-text-field',{attrs:{"color":"#574dad","label":"Buscador por nombre","prepend-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-card',{attrs:{"elevation":"5"}}),_c('div',{staticClass:"aerodromos row"},[(!_vm.aerodromosFiltradosPorNombre.length)?_c('p',{staticClass:"pa-3"},[_vm._v(" No hay aeródromos con este nombre ")]):_vm._l((_vm.aerodromosFiltradosPorNombre),function(ref,i){
        var aerodromo = ref.data;
        var id = ref.id;
return _c('div',{key:i,staticClass:"pa-3 col-6"},[_c('v-card',{staticClass:"pa-3 text-center",attrs:{"elevation":"5"}},[_c('v-text-field',{attrs:{"label":"Nombre"},on:{"keypress":function($event){_vm.formulariosModificados[i] = true}},model:{value:(aerodromo.nombre),callback:function ($$v) {_vm.$set(aerodromo, "nombre", $$v)},expression:"aerodromo.nombre"}}),_c('v-text-field',{attrs:{"label":"Correo de contacto"},on:{"keypress":function($event){_vm.formulariosModificados[i] = true}},model:{value:(aerodromo.email),callback:function ($$v) {_vm.$set(aerodromo, "email", $$v)},expression:"aerodromo.email"}}),_c('div',{staticClass:"mb-5",staticStyle:{"border-bottom":"1px solid black"}},[_c('gmap-autocomplete',{attrs:{"value":aerodromo.direccion,"placeholder":"Ingrese aquí la dirección del aeródromo","options":{
              componentRestrictions: { country: 'cl' },
              fields: ['geometry', 'formatted_address'],
              strictBounds: true,
            }},on:{"place_changed":function($event){return _vm.setPlace(arguments[0], i)}}})],1),_c('GmapMap',{staticStyle:{"width":"100%","height":"300px"},attrs:{"center":{
            lat: aerodromo.position.lat,
            lng: aerodromo.position.lng,
          },"zoom":_vm.zoom,"map-type-id":"hybrid"}},[_c('GmapMarker',{ref:"myMarker",refInFor:true,attrs:{"position":{
              lat: aerodromo.position.lat,
              lng: aerodromo.position.lng,
            }}})],1),_c('v-textarea',{staticClass:"mt-3",attrs:{"label":"Información relevante"},on:{"input":function($event){_vm.formulariosModificados[i] = true}},model:{value:(aerodromo.informacion),callback:function ($$v) {_vm.$set(aerodromo, "informacion", $$v)},expression:"aerodromo.informacion"}}),_c('v-btn',{class:{ 'white--text': _vm.formulariosModificados[i] },attrs:{"color":_vm.formulariosModificados[i] ? '#574dad' : ''},on:{"click":function($event){return _vm.updateAerodromo(i, id)}}},[_vm._v("Actualizar")]),_c('v-btn',{staticClass:"white--text ml-4",attrs:{"color":"red"},on:{"click":function($event){return _vm.eliminarAerodromo(id)}}},[_vm._v("Eliminar")])],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }