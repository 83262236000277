<template>
  <v-container class="mx-auto">
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <v-combobox
            dense
            outlined
            v-model="vuelo.origen"
            label="origen"
            :items="soloDestinos"
            id="setOrigen"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-combobox
            dense
            outlined
            v-model="vuelo.destino"
            label="destino"
            id="setDestino"
            :items="soloDestinos"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            dense
            outlined
            :color="colors.mainPurple"
            v-model="vuelo.aerodromoOrigen"
            label="Aerodromo origen"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            dense
            outlined
            :color="colors.mainPurple"
            v-model="vuelo.aerodromoDestino"
            label="Aerodromo destino"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            dense
            outlined
            :color="colors.mainPurple"
            v-model="vuelo.direccionOrigen"
            label="Dirección origen"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            dense
            outlined
            :color="colors.mainPurple"
            v-model="vuelo.direccionDestino"
            label="Dirección destino"
          />
        </v-col>
        <v-col cols="12">
          <p>Equipaje permitido:</p>
          <div class="d-flex align-center">
            <v-checkbox
              label="Equipaje de mano"
              v-model="vuelo.equipaje.mano"
              :color="colors.mainPurple"
              class="pr-5"
            />
            <v-checkbox
              label="Equipaje de cabina"
              v-model="vuelo.equipaje.cabina"
              :color="colors.mainPurple"
              class="pr-5"
            />
          </div>
        </v-col>
        <v-row>
          <v-col cols="12" md="6">
            <v-date-picker
              full-width
              no-title
              show-adjacent-months
              :min="new Date().toISOString().slice(0, 10)"
              label="fecha"
              dense
              outlined
              v-model="vuelo.fecha"
              first-day-of-week="1"
              locale="es"
            ></v-date-picker>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  v-model="vuelo.horaDespegue"
                  label="Hora embarque"
                  id="setHoraDespegue"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  v-model="vuelo.horaAterrizaje"
                  id="setHoraAterrizaje"
                  label="Hora aterrizaje"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  outlined
                  v-model="vuelo.img"
                  label="img"
                  id="setImg"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  dense
                  outlined
                  v-model="vuelo.aeronave"
                  label="aeronave"
                  id="setAeronave"
                  :items="aeronaves"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  dense
                  outlined
                  v-model="vuelo.operador"
                  label="operador"
                  :items="nombresOperadores"
                  id="setOperador"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  v-model="vuelo.precio"
                  type="number"
                  label="precio"
                  id="setPrecio"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  v-model="vuelo.capacidad"
                  type="number"
                  label="capacidad"
                  id="setCapacidad"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-row>
    </v-container>
    <v-btn
      @click="addVuelo"
      :color="colors.mainPurple"
      rounded
      dark
      width="100%"
      >Agregar vuelo</v-btn
    >
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import utils from "@/helpers/utils";
const { formatString } = utils;

export default {
  name: "AgregarVuelo",
  data() {
    return {
      vuelo: {
        aeronave: "",
        aerodromoOrigen: "",
        aerodromoDestino: "",
        destino: "",
        direccionOrigen: "",
        direccionDestino: "",
        equipaje: {
          mano: false,
          cabina: false,
        },
        fecha: "",
        horaAterrizaje: "",
        horaDespegue: "",
        img: "",
        origen: "",
        precio: "",
        capacidad: "",
        operador: "",
        ventas: 0,
        operador: "",
        status: "Abierto",
      },
      aeronaves: ["Avión", "Helicóptero"],
    };
  },
  methods: {
    ...mapActions("Vuelos", ["agregarVuelo"]),
    async addVuelo() {
      //convertir a numero
      this.vuelo.precio = +this.vuelo.precio;
      this.vuelo.capacidad = +this.vuelo.capacidad;
      //formatear en minusculas y sin tildes
      this.vuelo.aeronave = formatString(this.vuelo.aeronave);
      //get operador id
      this.vuelo.operador = this.getOperadorByName(this.vuelo.operador);
      delete this.vuelo.operador.rating;
      delete this.vuelo.operador.calificaciones;

      await this.agregarVuelo(JSON.parse(JSON.stringify(this.vuelo)));
      this.vuelo.origen = "";
      this.vuelo.destino = "";
      this.vuelo.direccionDestino = "";
      this.vuelo.direccionOrigen = "";
      this.vuelo.aerodromoOrigen = "";
      this.vuelo.aerodromoDestino = "";
      this.vuelo.fecha = "";
      this.vuelo.horaAterrizaje = "";
      this.vuelo.horaDespegue = "";
      this.vuelo.img = "";
      this.vuelo.aeronave = "";
      this.vuelo.precio = "";
      this.vuelo.capacidad = "";
      this.vuelo.operador = "";
      this.vuelo.equipaje.mano = false;
      this.vuelo.equipaje.cabina = false;
    },
  },
  computed: {
    ...mapState(["colors"]),
    ...mapGetters("Vuelos", ["soloDestinos"]),
    ...mapGetters("Operadores", ["getOperadorByName"]),
    ...mapState("Operadores", ["operadores"]),
    nombresOperadores() {
      const nombresOperadores = this.operadores.map((o) => o.nombre);

      return nombresOperadores;
    },
  },
  title() {
    return `Agregar vuelo - HummingBird`;
  },
};
</script>

<style>
</style>