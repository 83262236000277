<template>
  <v-form v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="pasajero.nombre"
            label="Nombre"
            required
            filled
            :color="colors.mainPurple"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="pasajero.rut"
            label="RUT"
            required
            filled
            :color="colors.mainPurple"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="pasajero.telefono"
            label="Teléfono"
            required
            filled
            :color="colors.mainPurple"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="pasajero.peso"
            label="Peso"
            required
            filled
            :color="colors.mainPurple"
            type="number"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-end">
        <v-btn @click="registrarPasajero" :color="colors.mainPurple" dark
          >Registrar pasajero</v-btn
        >
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "NuevoPasajeroForm",
  data() {
    return {
      pasajero: {
        peso: "",
        nombre: "",
      },
    };
  },
  methods: {
    registrarPasajero() {
      const { pasajero } = this;
      if (!pasajero.peso || !pasajero.nombre)
        return alert("Debe agregar al menos un nombre y un peso");
      this.$emit("registrarPasajero", pasajero);
      this.pasajero = {};
    },
  },
  computed: {
    ...mapState(["colors"]),
  },
};
</script>

<style></style>
