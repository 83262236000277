<template>
  <div class="pt-1">
    <div class="d-flex justify-space-between">
      <v-btn
        @click="nuevaOperacionModal = true"
        :color="colors.mainPurple"
        dark
        class="mb-5"
        >Registrar nueva operación</v-btn
      >
      <div>
        <v-btn
          x-large
          :color="template == 'table' ? colors.mainPurple : 'gray'"
          @click="template = 'table'"
          icon
          class="ma-2"
        >
          <v-icon>mdi-table</v-icon>
        </v-btn>
        <v-btn
          x-large
          icon
          class="ma-2"
          :color="template == 'calendar' ? colors.mainPurple : 'gray'"
          @click="template = 'calendar'"
        >
          <v-icon>mdi-calendar</v-icon>
        </v-btn>
      </div>
    </div>
    <v-dialog v-model="nuevaOperacionModal">
      <v-card class="px-15 py-10">
        <div class="pl-3 mb-3">
          <h1>Nueva operación</h1>
          <v-divider></v-divider>
        </div>
        <OperacionForm @registrarOperacion="registrarOperacion" />
      </v-card>
    </v-dialog>

    <v-dialog v-model="editarOperacionModal">
      <v-card class="px-15 py-10">
        <div class="pl-3 mb-3">
          <h1>
            Editar operación:
            {{
              operacionAEditar.data &&
                `${
                  operacionAEditar.data.aerodromo.name
                } | ${operacionAEditar.data.ventas
                  .map((v) => v.comprador)
                  .filter(Boolean)
                  .join(", ")}`
            }}
          </h1>
          <v-divider></v-divider>
        </div>
        <OperacionForm
          :operacion="operacionAEditar.data"
          @registrarOperacion="actualizarOperacion"
          @eliminarOperacion="eliminarOperacion"
          btnLabel="Editar operacion"
        />
      </v-card>
    </v-dialog>
    <v-data-table
      v-show="template == 'table'"
      :headers="headers"
      :items="operaciones"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:item.id="{ item: { id } }">
        <v-btn icon @click="editarOperacion({ event: { id } })">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <section
      v-show="template == 'calendar'"
      class="calendar"
      style="height: 550px"
    >
      <div class="d-flex align-center">
        <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <h3 class="text-capitalize">{{ fechaDelCalendario }}</h3>
        <v-btn icon class="ma-2" @click="$refs.calendar.next()">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
      <v-calendar
        ref="calendar"
        v-model="value"
        :events="events"
        type="month"
        @click:event="editarOperacion"
        @change="getDetailsFromChange"
      ></v-calendar>
    </section>
  </div>
</template>

<script>
import OperacionForm from "./OperacionForm.vue";
import { mapState } from "vuex";
import firebase from "firebase";
import moment from "moment";

export default {
  name: "Operaciones",
  data() {
    return {
      value: "",
      template: "calendar",
      nuevaOperacionModal: false,
      editarOperacionModal: false,
      operacionAEditar: {},
      fechaDelCalendario: moment(new Date())
        .locale("es")
        .format("MMMM YYYY"),
      headers: [
        { text: "Fecha", value: "data.fecha" },
        { text: "Experiencia", value: "data.experiencia.name" },
        { text: "Hora", value: "data.hora" },
        { text: "Aeródromo", value: "data.aerodromo.name" },
        { text: "Operador", value: "data.operador.name" },
        { text: "Aeronave", value: "data.aeronave" },
        { text: "Comprador", value: "data.venta.comprador" },
        { text: "", value: "id" },
      ],
    };
  },
  methods: {
    setFechaDelCalendarioLabel() {},
    getDetailsFromChange(e) {
      this.fechaDelCalendario = moment(new Date(e.start.date))
        .locale("es")
        .format("MMMM YYYY");
    },
    eliminarOperacion() {
      const decision = confirm(
        "¿Estás seguro de querer eliminar esta operación?"
      );
      if (decision) {
        const {
          operacionAEditar: { id },
        } = this;
        firebase
          .firestore()
          .collection("operaciones")
          .doc(id)
          .delete();
        this.editarOperacionModal = false;
      }
    },
    editarOperacion({ event }) {
      const { operaciones } = this;
      const { id } = event;
      this.operacionAEditar = operaciones.find((o) => o.id == id);
      this.editarOperacionModal = true;
    },
    async actualizarOperacion(operacion) {
      try {
        this.editarOperacionModal = false;
        const {
          operacionAEditar: { id },
        } = this;
        await firebase
          .firestore()
          .collection("operaciones")
          .doc(id)
          .update(operacion);
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async registrarOperacion(operacion) {
      try {
        this.nuevaOperacionModal = false;
        await firebase
          .firestore()
          .collection("operaciones")
          .add(operacion);
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  },
  components: {
    OperacionForm,
  },
  computed: {
    ...mapState(["colors"]),
    ...mapState("Operaciones", ["operaciones"]),
    events() {
      const {
        colors: { mainPurple },
        operaciones,
      } = this;
      return operaciones.map(({ data: operacion, id }) => {
        return {
          name: `${operacion.aerodromo.name}`,
          start: operacion.fecha,
          end: operacion.fecha,
          color: mainPurple,
          id,
        };
      });
    },
  },
};
</script>

<style></style>
